import React, { useState } from 'react'

import Layout from '../../layout/BasicPage'
import SEO from '../../components/SEO'
import Section from '../../components/Section'
import DangerousHTML from '../../components/DangerousHTML'
import HubspotForm from '../../components/HubspotForm'

import './contact-us.scss'

const ContactUs = ({ data, location }) => {
	const [message, setMessage] = useState(null)

	return (
		<Layout>
			<Section
				className='contact'
				title='Contact Us'
				subtitle='We are very busy delivering value to our clients – every day – but when someone who is just as busy takes time to reach out to us, we always make time to reply. '
			>
				<span>
					Media Contact:{' '}
					<a
						href='mailto:ryan.wagner@ogilvy.com'
						target='_blank'
						rel='noopener noreferrer'
					>
						Ryan Wagner
					</a>
				</span>

				<HubspotForm
					hubspotFormID='c7847a78-2c84-458a-b96f-6d9d1a6b3852'
					onSubmitted={setMessage}
				/>
				{message && <DangerousHTML>{message}</DangerousHTML>}
			</Section>
		</Layout>
	)
}

export default ContactUs
